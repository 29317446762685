import { computed } from 'vue'

export default function useAuth() {
  const csrfToken = computed(() => getCookie('csrftoken'))

  function getCookie(name) {
    // Shamelessly stolen from client.js
    name = `${name}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  return { csrfToken }
}
