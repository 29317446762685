import { useToast } from '@inkline/inkline/composables'

class PicselliaToast {
  static default(props) {
    const toast = useToast()

    toast.show({
      title: props.message,
      duration: props.duration,
      position: 'top',
    })

    return toast
  }

  static error(props) {
    const toast = useToast()

    toast.show({
      title: props.message ?? 'Service unavailable... Please contact support.',
      duration: props.duration,
      position: 'top',
      color: 'danger',
    })

    return toast
  }

  static warning(props) {
    const toast = useToast()

    toast.show({
      title: props.message,
      duration: props.duration,
      position: 'top',
      color: 'warning',
    })

    return toast
  }

  static success(props) {
    const toast = useToast()

    toast.show({
      title: props.message,
      duration: props.duration,
      position: 'top',
      color: 'success',
    })

    return toast
  }

  static info(props) {
    const toast = useToast()

    toast.show({
      title: props.message,
      duration: props.duration,
      position: 'top',
      color: 'info',
    })

    return toast
  }
}

export default PicselliaToast
