import PageComponent from '@/pages/auth/Signin.vue'
import { initSentry } from '@/js/sentry.js'
import FallbackLayout from '@/layouts/Fallback.vue'
import { Inkline } from '@inkline/inkline'
import { createApp, defineComponent, h } from 'vue'
import 'iconify-icon'

const app = createApp({
  name: 'App',
  components: {
    Page: PageComponent,
    Layout: getPageLayout(),
  },
})
app.use(Inkline, {
  colorMode: 'light',
})
initSentry(app)
app.mount('#app')

function getPageLayout() {
  const Layout = PageComponent.layout ?? FallbackLayout
  if (Array.isArray(Layout))
    return renderLayouts(Layout)
  return Layout
}

function renderLayouts(Layouts) {
  return defineComponent({
    setup(props, { slots }) {
      const renderLayoutsRecursive = (RemainingLayouts, slot) => {
        if (RemainingLayouts.length === 0) {
          return slot?.()
        }

        const [CurrentLayout, ...rest] = RemainingLayouts
        return h(CurrentLayout, {}, {
          default: () => renderLayoutsRecursive(rest, slot),
        })
      }

      return () => renderLayoutsRecursive([...Layouts], slots.default)
    },
  })
}
