import * as utils from '@modules/@grozav/utils'

export function clone(source) {
  if (Array.isArray(source)) {
    const target = source.slice().map(clone)
    const targetKeys = Object.keys(target)

    Object.keys(source)
      .filter(key => !targetKeys.includes(key))
      .forEach((key) => {
        target[key] = source[key]
      })

    return target
  }
  else if (typeof source === 'object' && source !== null && !(source instanceof Date) && !(source instanceof File)) {
    return Object.keys(source).reduce((acc, key) => {
      acc[key] = clone(source[key])

      return acc
    }, {})
  }

  return source
}

export const uid = utils.uid
export const isVisible = utils.isVisible
export const on = utils.on
export const off = utils.off
export const focusFirstDescendant = utils.focusFirstDescendant
export const getValueByPath = utils.getValueByPath
export const setValueByPath = utils.setValueByPath
export const setValuesAlongPath = utils.setValuesAlongPath
export const createEventBus = utils.createEventBus
export const isFunction = utils.isFunction
export const addClass = utils.addClass
export const removeClass = utils.removeClass
export const isKey = utils.isKey
export const isFocusable = utils.isFocusable
export const getStyleProperty = utils.getStyleProperty
export const toCamelCase = utils.toCamelCase
export const filterKeys = utils.filterKeys
export const memoize = utils.memoize
export const markSearchString = utils.markSearchString
export const debounce = utils.debounce
