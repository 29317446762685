<script setup>
import { useStorage } from '@vueuse/core'

const dark = useStorage('picsellia-theme', 'light')
</script>

<template>
  <main :class="[dark]">
    <slot />
  </main>
</template>
